<template>
	<div>
		<van-nav-bar
			title="評估結果"
			left-arrow
			fixed
			:z-index="1000"
			class="qjc-nav-bar"
			@click-left="$router.replace('/user/history')"
		>
			<van-image 
				:src="require('@/assets/images/home.png')" 
				slot="right"
				width="0.32rem"
				height="0.32rem"
				style="vertical-align: middle;"
				@click="$router.push('/')"
			/>
		</van-nav-bar>
		
		<div class="result">
			<div class="infomation">
				<dl class="danger" :class="res.base.health_type==1?'jiankang':(res.base.health_type==2?'yajiankang':(res.base.health_type==3?'yiliaoxuqiu':''))">
					<dd class="danger-val qjc-c-dark qjc-ftw-b">{{ res.base.health_type==1?'低':(res.base.health_type==2?'中':(res.base.health_type==3?'高':'未知')) }}</dd>
					<dd class="danger-level">{{ res.base.health_type==1?'健康':(res.base.health_type==2?'亞健康':(res.base.health_type==3?'醫療需求者':'未知')) }}</dd>
					<dt>健康危險係數</dt>
				</dl>
				<van-grid :column-num="3" :border="false">
					<van-grid-item class="qjc-nowrap">
						<span>身高：<b class="qjc-c-dark">{{ res.base.height }}cm</b></span>
					</van-grid-item>
					<van-grid-item class="qjc-nowrap">
						<span>體重：<b class="qjc-c-dark">{{ res.base.weight }}kg</b></span>
					</van-grid-item>
					<van-grid-item class="qjc-nowrap">
						<span>體脂率：<b class="qjc-c-dark">{{ res.base.fat }}%</b></span>
					</van-grid-item>
					<!-- <van-grid-item class="qjc-nowrap">
						<span>胸圍：<b class="qjc-c-dark">{{ res.base.bust }}cm</b></span>
					</van-grid-item> -->
					<van-grid-item class="qjc-nowrap">
						<span>腰圍：<b class="qjc-c-dark">{{ res.base.waist }}cm</b></span>
					</van-grid-item>
					<van-grid-item class="qjc-nowrap">
						<span>臀圍：<b class="qjc-c-dark">{{ res.base.hip }}cm</b></span>
					</van-grid-item>
				</van-grid>
				<p class="hint">體型信息和生化指標信息僅限<span @click="edit" class="qjc-c-primary">修改</span>一次</p>
			</div>
			<van-grid class="person qjc-bg-fff" :column-num="3" :border="false">
				<van-grid-item>
					<dl>
						<dt>預期壽命</dt>
						<dd class="qjc-c-primary"><b>{{ res.m01.pre_lift }}</b>歲</dd>
					</dl>
					<dl>
						<dt>體型評估</dt>
						<dd class="qjc-c-primary"><b>{{ res.m05.body_g[1] }}</b></dd>
					</dl>
				</van-grid-item>
				<van-grid-item>
					<van-image
						width="1.84rem"
						height="5.5rem"
						:src="this.res.base.sex == 1?require('@/assets/images/man.png'):(this.res.base.sex == 2?require('@/assets/images/wuman.png'):null)"
					/>
				</van-grid-item>
				<van-grid-item>
					<dl>
						<dt>百歲概率</dt>
						<dd class="qjc-c-primary"><b>{{ res.m01.lift_rates.slice(-1)[0] }}</b>%</dd>
					</dl>
					<dl>
						<dt>終生醫療費用預測</dt>
						<dd class="qjc-c-primary"><b>{{ res.m01.lift_fee }}</b>港元</dd>
					</dl>
				</van-grid-item>
			</van-grid>
			
			<div class="list">
				<!-- 模块名列表 -->
				<van-sticky :z-index="1000" :offset-top="offsetTop">
					<ul class="list-title qjc-nowrap qjc-c-dark">
						<li v-if="modules.biom" @click="clickCheckModule('biom')" :class="{ active: active == 'biom' }">生化指標評估</li>
						<li v-if="modules.health" @click="clickCheckModule('health')" :class="{ active: active == 'health' }">未來健康風險預測</li>
						<li v-if="modules.total" @click="clickCheckModule('total')" :class="{ active: active == 'total' }">總體健康評估和生命預測</li>
						<li v-if="modules.bodily" @click="clickCheckModule('bodily')" :class="{ active: active == 'bodily' }">體型評估</li>
						<li v-if="modules.costs" @click="clickCheckModule('costs')" :class="{ active: active == 'costs' }">醫療費用預測</li>
						<li v-if="modules.illness" @click="clickCheckModule('illness')" :class="{ active: active == 'illness' }">未来疾病风险预测</li>
						<li v-if="modules.noSick" @click="clickCheckModule('noSick')" :class="{ active: active == 'noSick' }">不患病&增寿</li>
						<li v-if="modules.improve" @click="clickCheckModule('improve')" :class="{ active: active == 'improve' }">健康管理和改善方案</li>
					</ul>
				</van-sticky>
				
				<div @touchstart="touchStart($event)">
					<van-swipe
						@change="slide" 
						:show-indicators="false" 
						:loop="false"
						ref="swp"
					>
						<van-swipe-item v-if="modules.biom" @touchmove="checkMove($event)">
							<!-- 生化指標評估 -->
							<div v-if="active == 'biom' || nextActive == 'biom'" class="biom qjc-texta-l qjc-bg-fff">
								<p>同族群（PLM，People Like Me）:與您相似的人群--與您同性別同年齡段的人群</p>
								<table class="qjc-wid-100" border="0" cellpadding="0" cellspacing="0">
									<tr>
										<th></th>
										<th class="qjc-ftw-n">主要檢查<br>項目</th>
										<th class="qjc-ftw-n">檢查<br>結果</th>
										<th>與臨床標準比較</th>
										<th class="group-v">與同類族群比較</th>
									</tr>
									<tr>
										<td>血液常規</td>
										<td>
											<ul>
												<li v-for="(cbc, index) in biomData.CBC">{{ biomData.CBC[index].item_d }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(cbc, index) in biomData.CBC"><span>{{ biomData.CBC[index].value }}</span>{{ biomData.CBC[index].unit }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(cbc, index) in biomData.CBC" :class="biomData.CBC[index].group_n == '異常'?'c-high':''">{{ biomData.CBC[index].group_n }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.CBC" :class="biomData.CBC[index].group_p == '偏高' || biomData.CBC[index].group_p == '相對較好' || biomData.CBC[index].group_p == '相同' || biomData.CBC[index].group_p == '偏低'?'c-high':(biomData.CBC[index].group_p == '適中' || biomData.CBC[index].group_p == '正常偏低' || biomData.CBC[index].group_p == '正常偏高'?'qjc-c-primary':(biomData.CBC[index].group_p == '優良'?'c-nice':''))">{{ biomData.CBC[index].group_p }}</li>
											</ul>
										</td>
									</tr>
									<tr>
										<td>血糖</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.bloodGlucose">{{ biomData.bloodGlucose[index].item_d }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.bloodGlucose"><span>{{ biomData.bloodGlucose[index].value }}</span>{{ biomData.bloodGlucose[index].unit }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.bloodGlucose" :class="biomData.bloodGlucose[index].group_n == '異常'?'c-high':''">{{ biomData.bloodGlucose[index].group_n }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.bloodGlucose" :class="biomData.bloodGlucose[index].group_p == '偏高' || biomData.bloodGlucose[index].group_p == '相對較好' || biomData.bloodGlucose[index].group_p == '相同' || biomData.bloodGlucose[index].group_p == '偏低'?'c-high':(biomData.bloodGlucose[index].group_p == '適中' || biomData.bloodGlucose[index].group_p == '正常偏低' || biomData.bloodGlucose[index].group_p == '正常偏高'?'qjc-c-primary':(biomData.bloodGlucose[index].group_p == '優良'?'c-nice':''))">{{ biomData.bloodGlucose[index].group_p }}</li>
											</ul>
										</td>
									</tr>
									<tr>
										<td>血脂肪</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.bloodLipids">{{ biomData.bloodLipids[index].item_d }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.bloodLipids"><span>{{ biomData.bloodLipids[index].value }}</span>{{ biomData.bloodLipids[index].unit }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.bloodLipids" :class="biomData.bloodLipids[index].group_n == '異常'?'c-high':''">{{ biomData.bloodLipids[index].group_n }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.bloodLipids" :class="biomData.bloodLipids[index].group_p == '偏高' || biomData.bloodLipids[index].group_p == '相對較好' || biomData.bloodLipids[index].group_p == '相同' || biomData.bloodLipids[index].group_p == '偏低'?'c-high':(biomData.bloodLipids[index].group_p == '適中' || biomData.bloodLipids[index].group_p == '正常偏低' || biomData.bloodLipids[index].group_p == '正常偏高'?'qjc-c-primary':(biomData.bloodLipids[index].group_p == '優良'?'c-nice':''))">{{ biomData.bloodLipids[index].group_p }}</li>
											</ul>
										</td>
									</tr>
									<tr>
										<td>肝膽功能</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.ALT">{{ biomData.ALT[index].item_d }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.ALT"><span>{{ biomData.ALT[index].value }}</span>{{ biomData.ALT[index].unit }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.ALT" :class="biomData.ALT[index].group_n == '異常'?'c-high':''">{{ biomData.ALT[index].group_n }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.ALT" :class="biomData.ALT[index].group_p == '偏高' || biomData.ALT[index].group_p == '相對較好' || biomData.ALT[index].group_p == '相同' || biomData.ALT[index].group_p == '偏低'?'c-high':(biomData.ALT[index].group_p == '適中' || biomData.ALT[index].group_p == '正常偏低' || biomData.ALT[index].group_p == '正常偏高'?'qjc-c-primary':(biomData.ALT[index].group_p == '優良'?'c-nice':''))">{{ biomData.ALT[index].group_p }}</li>
											</ul>
										</td>
									</tr>
									<tr>
										<td>腎功能</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.renalFunc">{{ biomData.renalFunc[index].item_d }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.renalFunc"><span>{{ biomData.renalFunc[index].value }}</span>{{ biomData.renalFunc[index].unit }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.renalFunc" :class="biomData.renalFunc[index].group_n == '異常'?'c-high':''">{{ biomData.renalFunc[index].group_n }}</li>
											</ul>
										</td>
										<td>
											<ul>
												<li v-for="(item, index) in biomData.renalFunc" :class="biomData.renalFunc[index].group_p == '偏高' || biomData.renalFunc[index].group_p == '相對較好' || biomData.renalFunc[index].group_p == '相同' || biomData.renalFunc[index].group_p == '偏低'?'c-high':(biomData.renalFunc[index].group_p == '適中' || biomData.renalFunc[index].group_p == '正常偏低' || biomData.renalFunc[index].group_p == '正常偏高'?'qjc-c-primary':(biomData.renalFunc[index].group_p == '優良'?'c-nice':''))">{{ biomData.renalFunc[index].group_p }}</li>
											</ul>
										</td>
									</tr>
								</table>
								<div>
									<h3 class="chart-t qjc-fts-28 qjc-c-dark">常見疾病風險倍率如下表：</h3>
									<div class="biom-chart" key="biom-chart">
										
									</div>
								</div>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.health" @touchmove="checkMove($event)">	
							<!-- 未來健康風險預測 -->
							<div v-if="active == 'health' || nextActive == 'health'" class="health qjc-bg-fff">
								<!-- 健康者 -->
								<div v-if="res.base.health_type == 1" class="health1 qjc-texta-l">
									<p>測量綜合生理年齡RealAge，目的是在希望您能體會個人健康現況，可以更注重個人健康促進，讓您未來能表現的更為年輕有活力。錯誤或不好的生活習慣，會讓人身體生理機能的年齡比實際歲數老化，並增加罹病機率和死亡機率。</p>
									<p>綜合生理年齡RealAge，是經研究分析海量的健康資料庫，依照個人的健康危險因子（如：生活習慣、運動飲食、….等）的多寡與影響程度，分別給予正負分來計算，換句話說：RealAge＝實際年齡±影響健康危險因子之加總值。</p>
									<p>透過綜合生理年齡RealAge的檢測，了解健康危險因子對自己身體生理的影響程度，未來能加強良好飲食、運動、生活習慣，確實做好健康管理、預防保健與養生，以延緩老化、降低罹病與失能機率。</p>
									<div class="qjc-texta-c">
										<dl>
											<dd class="qjc-c-primary">
												<b>{{ res.base.age }}</b>歲
											</dd>
											<dt>您的實際年齡</dt>
										</dl>
										<dl>
											<dd class="qjc-c-primary">
												<b>{{ res.m02.realage }}</b>歲
											</dd>
											<dt>您的綜合生理年齡 Real Age</dt>
										</dl>
									</div>
									<p>綜合生理年齡是從醫學、生物學的角度來衡量的年齡，表示身體的成長、成熟或衰老的程度。米因在千萬人口的醫療健康大數據庫基礎上，挖掘出綜合生理年齡所對應的關鍵指標，為您提供詳細、準確的大數據分析結果。</p>
								</div>
								<!-- 亚健康 -->
								<ul v-else-if="res.base.health_type == 2">
									<li v-for="item in threeHighsClassify()">
										<h3 class="qjc-texta-l qjc-c-dark qjc-fts-32">{{item.name }}</h3>
										<dl>
											<dd class="qjc-c-primary qjc-ftw-b">
												<span>{{ item.rate }}</span>倍
											</dd>
											<dt class="qjc-ftw-b">患病風險是同類族群平均患病風險的</dt>
										</dl>
										<p class="qjc-texta-l">
											<span class="qjc-c-dark">主要徵狀：</span>{{ item.harm }}
										</p>
										<p class="qjc-texta-l"><span class="qjc-c-dark qjc-ftw-b">註：</span>同族群（PLM，People Like Me）:與您相似的人群--與您同性別同年齡段的人群。</p>
									</li>
								</ul>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.total" @touchmove="checkMove($event)">
							<!-- 总体健康评估和生命预测 -->
							<div v-if="active == 'total' || nextActive == 'total'" class="total qjc-bg-fff">
								<van-grid class="total-t qjc-texta-l" :center="false" :border="false" :column-num="3" gutter="0.16rem">
									<van-grid-item>
										<p class="qjc-c-dark qjc-wid-100"><b>預期壽命</b></p>
										<div class="qjc-c-primary qjc-wid-100">
											<b><span>{{ res.m01.pre_lift }}</span>歲</b>
											<van-image
												width="0.4rem"
												height="0.25rem"
												:src="require('@/assets/images/yuqishouming.png')"
											/>
										</div>
									</van-grid-item>
									<van-grid-item>
										<p class="qjc-c-dark qjc-wid-100"><b>百歲概率</b></p>
										<div class="qjc-c-primary qjc-wid-100">
											<b><span>{{ res.m01.lift_rates.slice(-1)[0] }}</span>%</b>
											<van-image
												width="0.36rem"
												height="0.36rem"
												:src="require('@/assets/images/baisuigailv.png')"
											/>
										</div>
									</van-grid-item>
									<van-grid-item>
										<p class="qjc-c-dark qjc-wid-100"><b>失能的平均概率</b></p>
										<div class="qjc-c-primary qjc-wid-100">
											<b><span>{{ res.m01.lost_ability }}</span>%</b>
											<van-image
												width="0.36rem"
												height="0.36rem"
												:src="require('@/assets/images/shinengdepingjungailv.png')"
											/>
										</div>
									</van-grid-item>
								</van-grid>
								<div class="total-chart" key="total-chart">
									
								</div>
								<div class="hint qjc-texta-l">
									<p><span class="qjc-c-primary">友情提示：</span>現時科技與生活環境之下，人類的壽限約120歲；但人類平均壽命約在80歲左右，距離120歲的壽限還有很大的進步空間。現在醫療科技進步，人類普遍面臨的是「生命」延長了，但因為不健康而生病，將導致不便或臥床，影響生活與生命品質，造成痛苦苟延殘喘的時間也可能跟著延長。我們將可能影響生活品質的時間當成「失能時間」。</p>
									<p>不生病或延緩病情，降低疾病對健康的影響，必須透過健康促進與健康管理，才能「防未病、斷欲病、緩既病」，進而有效的「延長健康的餘命」。</p>
								</div>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.bodily" @touchmove="checkMove($event)">
							<!-- 体型评估 -->
							<div v-if="active == 'bodily' || nextActive == 'bodily'" class="bodily">
								<div class="res">
									<h4 class="qjc-c-dark qjc-texta-l qjc-ftw-b qjc-fts-28">您的體脂率<span class="res-bfr qjc-c-primary">{{ ['1','4','7'].indexOf(res.m05.body_g[0])!=-1?'偏低':(['2','5','8'].indexOf(res.m05.body_g[0])!=-1?'適中':(['3','6','9'].indexOf(res.m05.body_g[0])!=-1?'偏高':'未知')) }}</span>，BMI<span class="qjc-c-primary">{{ res.m05.bmi_g[1] }}</span>，在同类族群中屬於<span class="qjc-c-primary">{{ res.m05.body_g[1] }}</span></h4>
									<table class="qjc-wid-100 qjc-bg-fff" border="0" cellpadding="0" cellspacing="0">
										<thead>
											<th>
												<span class="BFR-t">體脂率(%)</span>
												<span class="BMI-t">BMI</span>
											</th>
											<th :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.bmi_g[1]=='偏低' }">偏低</th>
											<th :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.bmi_g[1]=='適中' }">適中</th>
											<th :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.bmi_g[1]=='偏高' }">偏高</th>
										</thead>
										<tr>
											<td :class="{ 'qjc-c-primary qjc-ftw-b': ['1','4','7'].indexOf(res.m05.body_g[0])!=-1 }">偏低</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='1'?require('@/assets/images/xiaoshou--blue.png'):require('@/assets/images/xiaoshou.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='1' }" class="img-hint">消瘦</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='4'?require('@/assets/images/jingshi--blue.png'):require('@/assets/images/jingshi.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='4' }" class="img-hint">精實</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='7'?require('@/assets/images/jianmei--blue.png'):require('@/assets/images/jianmei.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='7' }" class="img-hint">健美</span>
											</td>
										</tr>
										<tr>
											<td :class="{ 'qjc-c-primary qjc-ftw-b': ['2','5','8'].indexOf(res.m05.body_g[0])!=-1 }">適中</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='2'?require('@/assets/images/miaotiao--blue.png'):require('@/assets/images/miaotiao.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='2' }" class="img-hint">苗條</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='5'?require('@/assets/images/shizhong--blue.png'):require('@/assets/images/shizhong.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='5' }" class="img-hint">適中</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='8'?require('@/assets/images/zhuangshuo--blue.png'):require('@/assets/images/zhuangshuo.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='8' }" class="img-hint">壯碩</span>
											</td>
										</tr>
										<tr>
											<td :class="{ 'qjc-c-primary qjc-ftw-b': ['3','6','9'].indexOf(res.m05.body_g[0])!=-1 }">偏高</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='3'?require('@/assets/images/yinxingfeipang--blue.png'):require('@/assets/images/yinxingfeipang.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='3' }" class="img-hint">隱性肥胖</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='6'?require('@/assets/images/yinxingfeipang2--blue.png'):require('@/assets/images/yinxingfeipang2.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='6' }" class="img-hint">隱性肥胖</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='9'?require('@/assets/images/feipang--blue.png'):require('@/assets/images/feipang.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='9' }" class="img-hint">肥胖</span>
											</td>
										</tr>
									</table>
								</div>
								<div class="targets qjc-texta-l qjc-ftw-b">
									<h4 class="qjc-c-dark qjc-fts-28">您的各項指標</h4>
									<div class="BMI qjc-bg-fff">
										<h5 class="targets-t">BMI
										<!-- <span :class="{ 'hint-h': res.m05.bmi_g[1] == '偏高','hint-m': res.m05.bmi_g[1] == '適中','hint-l': res.m05.bmi_g[1] == '偏低' }">{{ res.m05.bmi_g[1] }}</span> -->
										</h5>
										<p class="targets-t-h">BMI指數是用體重公斤數除以身高米數平方得出的數字，是目前國際上常用的衡量人體胖瘦程度以及是否健康的一個標準。</p>
										<!-- 您的 -->
										<dl class="you">
											<dt class="qjc-ftw-b qjc-fts-28">您的BMI：{{ res.base.bmi }}</dt>
											<dd class='line'>
												<!-- 背景色条和分割数值 -->
												<ul class="qjc-clearfix">
													<li class="s1 qjc-fl"><span>{{BMIValue[0]}}</span></li>
													<li class="s2 qjc-fl"><span>{{BMIValue[1]}}</span></li>
													<li class="s3 qjc-fl"></li>
												</ul>
												<!-- 切割处白色背景 -->
												<ol class="qjc-clearfix">
													<li class="qjc-fl"></li>
													<li class="qjc-fl"></li>
													<li class="qjc-fl"></li>
												</ol>
												<!-- 圆形标记 -->
												<div :class="{ 'step1': res.base.bmi<BMIValue[0],'step2': res.base.bmi>BMIValue[0] && res.base.bmi<BMIValue[1],'step3': res.base.bmi>BMIValue[1],}" :style="{ left: res.base.bmi<(BMIValue[0] - BMISpac)?0:(res.base.bmi>(BMIValue[1] + BMISpac)?100:(res.base.bmi-(BMIValue[0] - BMISpac))/(BMISpac * 3)*100)+'%', width: (100-(res.base.bmi<(BMIValue[0] - BMISpac)?0:(res.base.bmi>(BMIValue[1] + BMISpac)?100:(res.base.bmi-(BMIValue[0] - BMISpac))/(BMISpac * 3)*100)))+'%' }" class="circle"></div>
											</dd>
										</dl>
										<!-- 同族群 -->
										<dl class="group" v-if="res.base.package != 3">
											<dt class="qjc-ftw-b qjc-fts-28">
												同族群平均值的BMI：{{ parseFloat(res.m05.bmi_g[2]) }} 
												<i @click="BMIGroup = !BMIGroup" class="iconfont iconyiwen1 qjc-relative">
													<div v-if="BMIGroup" class="qjc-bg-fff qjc-absolute">
														<span>同族群（PLM，People Like Me）:與您相似的人群--與您同性別同年齡段的人群。</span>
														<div class="arrow"></div>
													</div>
												</i>
											</dt>
											<dd class='line'>
												<!-- 背景色条和分割数值 -->
												<ul class="qjc-clearfix">
													<li class="s1 qjc-fl"><span>{{BMIValue[0]}}</span></li>
													<li class="s2 qjc-fl"><span>{{BMIValue[1]}}</span></li>
													<li class="s3 qjc-fl"></li>
												</ul>
												<!-- 切割处白色背景 -->
												<ol class="qjc-clearfix">
													<li class="qjc-fl"></li>
													<li class="qjc-fl"></li>
													<li class="qjc-fl"></li>
												</ol>
												<!-- 圆形标记 -->
												<div :class="{ 'step1': res.m05.bmi_g[2]<BMIValue[0],'step2': res.m05.bmi_g[2]>BMIValue[0] && res.m05.bmi_g[2]<BMIValue[1],'step3': res.m05.bmi_g[2]>BMIValue[1],}" :style="{ left: res.m05.bmi_g[2]<(BMIValue[0] - BMISpac)?0:(res.m05.bmi_g[2] > (BMIValue[1] + BMISpac)?100:(res.m05.bmi_g[2]-(BMIValue[0] - BMISpac))/(BMISpac * 3)*100)+'%', width: (100-(res.m05.bmi_g[2]<(BMIValue[0] - BMISpac)?0:(res.m05.bmi_g[2]>(BMIValue[1] + BMISpac)?100:(res.m05.bmi_g[2]-(BMIValue[0] - BMISpac))/(BMISpac * 3)*100)))+'%' }" class="circle"></div>
											</dd>
										</dl>
										<p class="remark">臨床標準範圍: &lt;18.5表示過輕，18.5～23表示正常，&gt;27表示過重。</p>
									</div>
									<div class="WHR qjc-bg-fff">
										<h5 class="targets-t">腰臀比（WHR）
										<!-- <span :class="{ 'hint-h': res.m05.whr_g[1] == '偏高','hint-m': res.m05.whr_g[1] == '適中','hint-l': res.m05.whr_g[1] == '偏低' }">{{ res.m05.whr_g[1] }}</span> -->
										</h5>
										<p class="targets-t-h">腰圍反映脂肪總量和脂肪分布的綜合指標,臀圍反映髖部骨骼和肌肉的發育情況。腰臀比值越大，腰腹或內臟就有可能堆積更多的脂肪。</p>
										<!-- 您的 -->
										<dl class="you">
											<dt class="qjc-ftw-b qjc-fts-28">您的WHR：{{ res.base.whr }}</dt>
											<dd class='line'>
												<!-- 背景色条和分割数值 -->
												<ul class="qjc-clearfix">
													<li class="s1 qjc-fl"><span>{{WHRValue[1]}}</span></li>
													<li class="s2 qjc-fl"></li>
												</ul>
												<!-- 切割处白色背景 -->
												<ol class="qjc-clearfix">
													<li class="qjc-fl"></li>
													<li class="qjc-fl"></li>
												</ol>
												<!-- 圆形标记 -->
												<div :class="{ 'step1': res.base.whr<WHRValue[1],'step2': res.base.whr>WHRValue[1] }" :style="{ left: res.base.whr<0?0:(res.base.whr>(WHRValue[1] * 2)?100:res.base.whr/(WHRValue[1] * 2)*100)+'%', width: (100-(res.base.whr<0?0:(res.base.whr>(WHRValue[1] * 2)?100:(res.base.whr)/(WHRValue[1] * 2)*100)))+'%' }" class="circle"></div>
											</dd>
										</dl>
										<!-- 同族群 -->
										<dl class="group" v-if="res.base.package != 3">
											<dt class="qjc-ftw-b qjc-fts-28">
												同族群平均值的WHR：{{ parseFloat(res.m05.whr_g[2]) }}
												<i @click="WHRGroup = !WHRGroup" class="iconfont iconyiwen1 qjc-relative">
													<div v-if="WHRGroup" class="qjc-bg-fff qjc-absolute">
														<span>同族群（PLM，People Like Me）:與您相似的人群--與您同性別同年齡段的人群。</span>
														<div class="arrow"></div>
													</div>
												</i>
											</dt>
											<dd class='line'>
												<!-- 背景色条和分割数值 -->
												<ul class="qjc-clearfix">
													<li class="s1 qjc-fl"><span>{{WHRValue[1]}}</span></li>
													<li class="s2 qjc-fl"></li>
												</ul>
												<!-- 切割处白色背景 -->
												<ol class="qjc-clearfix">
													<li class="qjc-fl"></li>
													<li class="qjc-fl"></li>
												</ol>
												<!-- 圆形标记 -->
												<div :class="{ 'step1': res.m05.whr_g[2]<WHRValue[1],'step2': res.m05.whr_g[2]>WHRValue[1] }" :style="{ left: res.m05.whr_g[2]<0?0:(res.m05.whr_g[2]>(WHRValue[1] * 2)?100:res.m05.whr_g[2]/(WHRValue[1] * 2)*100)+'%', width: (100-(res.m05.whr_g[2]<0?0:(res.m05.whr_g[2]>(WHRValue[1] * 2)?100:(res.m05.whr_g[2])/(WHRValue[1] * 2)*100)))+'%' }" class="circle"></div>
											</dd>
										</dl>
										<p class="remark">臨床標準範圍: 男性&lt;0.92，女性&lt;0.88。</p>
									</div>
									<div class="BFR qjc-bg-fff">
										<h5 class="targets-t">體脂率（BFR）%
										<!-- <span :class="{ 'hint-h': res.m05.fat_g[1] == '偏高','hint-m': res.m05.fat_g[1] == '適中','hint-l': res.m05.fat_g[1] == '偏低' }">{{ res.m05.fat_g[1] }}</span> -->
										</h5>
										<p class="targets-t-h">體脂率是指人體內脂肪重量在人體總體重中所占的比例，又稱體脂百分數，它反映人體內脂肪含量的多少。 肥胖會提高罹患各種疾病的風險，例如高血壓、糖尿病、高血脂等。</p>
										<!-- 您的 -->
										<dl class="you">
											<dt class="qjc-ftw-b qjc-fts-28">您的BFR：{{ res.base.fat }}</dt>
											<dd class='line'>
												<!-- 背景色条和分割数值 -->
												<ul class="qjc-clearfix">
													<li class="s1 qjc-fl"><span>{{BFRValue1}}</span></li>
													<li class="s2 qjc-fl"><span>{{BFRValue2}}</span></li>
													<li class="s3 qjc-fl"></li>
												</ul>
												<!-- 切割处白色背景 -->
												<ol class="qjc-clearfix">
													<li class="qjc-fl"></li>
													<li class="qjc-fl"></li>
													<li class="qjc-fl"></li>
												</ol>
												<!-- 圆形标记 -->
												<div :class="{ 'step1': res.base.fat<BFRValue1,'step2': res.base.fat>BFRValue1 && res.base.fat<BFRValue2,'step3': res.base.fat>BFRValue2 }" :style="{ left: res.base.fat<(BFRValue1 - BFRSpac)?0:(res.base.fat>(BFRValue2 + BFRSpac)?100:(res.base.fat-(BFRValue1 - BFRSpac))/(BFRSpac * 3)*100)+'%', width: (100-(res.base.fat<(BFRValue1 - BFRSpac)?0:(res.base.fat>(BFRValue2 + BFRSpac)?100:(res.base.fat-(BFRValue1 - BFRSpac))/(BFRSpac * 3)*100)))+'%' }" class="circle"></div>
											</dd>
										</dl>
										<!-- 同族群 -->
										<dl class="group" v-if="res.base.package != 3">
											<dt class="qjc-ftw-b qjc-fts-28">
												同族群平均值的BFR：{{ parseFloat(res.m05.fat_g[2]) }}
												<i @click="BFRGroup = !BFRGroup" class="iconfont iconyiwen1 qjc-relative">
													<div v-if="BFRGroup" class="qjc-bg-fff qjc-absolute">
														<span>同族群（PLM，People Like Me）:與您相似的人群--與您同性別同年齡段的人群。</span>
														<div class="arrow"></div>
													</div>
												</i>
											</dt>
											<dd class='line'>
												<!-- 背景色条和分割数值 -->
												<ul class="qjc-clearfix">
													<li class="s1 qjc-fl"><span>{{BFRValue1}}</span></li>
													<li class="s2 qjc-fl"><span>{{BFRValue2}}</span></li>
													<li class="s3 qjc-fl"></li>
												</ul>
												<!-- 切割处白色背景 -->
												<ol class="qjc-clearfix">
													<li class="qjc-fl"></li>
													<li class="qjc-fl"></li>
													<li class="qjc-fl"></li>
												</ol>
												<!-- 圆形标记 -->
												<div :class="{ 'step1': res.m05.fat_g[2]<BFRValue1,'step2': res.m05.fat_g[2]>BFRValue1 && res.m05.fat_g[2]<BFRValue2,'step3': res.m05.fat_g[2]>BFRValue2 }" :style="{ left: res.m05.fat_g[2]<(BFRValue1 - BFRSpac)?0:(res.m05.fat_g[2]>(BFRValue2 + BFRSpac)?100:(res.m05.fat_g[2]-(BFRValue1 - BFRSpac))/(BFRSpac * 3)*100)+'%', width: (100-(res.m05.fat_g[2]<(BFRValue1 - BFRSpac)?0:(res.m05.fat_g[2]>(BFRValue2 + BFRSpac)?100:(res.m05.fat_g[2]-(BFRValue1 - BFRSpac))/(BFRSpac * 3)*100)))+'%' }" class="circle"></div>
											</dd>
										</dl>
										<p class="remark">臨床標準範圍: 男性30歲以下14~20，30歲及以上17~23； 女性30歲以下17~24，30歲及以上20~27。</p>
									</div>
								</div>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.costs" @touchmove="checkMove($event)">
							<!-- 医疗费用预测 -->
							<div v-if="active == 'costs' || nextActive == 'costs'" class="costs qjc-bg-fff">
								<ul>
									<li class="qjc-clearfix" v-show="res.base.package == 1">
										<dl class="qjc-fl qjc-texta-l">
											<dd class="qjc-fts-28">
												<b>{{ res.m10.person_allfee }}</b>
												港元
											</dd>
											<dt>終生累計醫療費用預估</dt>
										</dl>
										<van-image
											width="0.9rem"
											height="0.82rem"
											class="qjc-fr"
											:src="require('@/assets/images/yiliaofeiyongyugu--red.png')"
										/>
									</li>
									<li class="qjc-clearfix">
										<dl class="qjc-fl qjc-texta-l">
											<dd class="qjc-fts-28">
												<b>{{ res.m10.person_yearsfee.slice(-1)[0] }}</b>
												港元
											</dd>
											<dt>{{res.m10.person_yearsfee == 10 ? '未來十年主要醫療費用演化趨勢' : res.m10.person_yearsfee == 5 ? '未來五年主要醫療費用演化趨勢':'未來三年主要醫療費用演化趨勢'}}</dt>
										</dl>
										<van-image
											width="0.9rem"
											height="0.82rem"
											class="qjc-fr"
											:src="require('@/assets/images/yiliaofeiyongyugu--blue.png')"
										/>
									</li>
								</ul>
								<div class="costs-chart" key="costChart">
									
								</div>
								<p class="qjc-texta-l"><span class="qjc-c-dark qjc-ftw-b">註：</span>同族群（PLM，People Like Me）:與您相似的人群--與您同性別同年齡段的人群。</p>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.illness" @touchmove="checkMove($event)">
							<!-- 未来疾病风险预测 -->
							<div v-if="active == 'illness' || nextActive == 'illness'" class="illness qjc-texta-l qjc-bg-fff">
								<div>
									<h3 class="qjc-c-dark">{{ res.base.health_type == 1?'系統疾病風險':(res.base.health_type == 2?'慢性病風險':(res.base.health_type == 3?'急重症風險':'')) }}</h3>
									<ul>
										<li v-for="(item, index) in res.m07.dis_risk">
											<div class="illness-t">
												<b class="name qjc-c-dark">{{ index+1 }}.{{ item.devg }}</b>
												<span @click="chartOn('risk',index, item, $event)" class="qjc-fr" v-if="res.base.package != 3">
													查看詳細圖表
													<van-icon
														name="arrow-down"
													/>
												</span>
											</div>
											<div class="chart qjc-hide" :key="item.devg">
												
											</div>
											<div class="prob qjc-texta-c qjc-clearfix">
												<dl class="qjc-fl" :class="res.base.health_type == 1 || res.base.package == 3 || res.base.package == 4 ?  'qjc-wid-100' : 'qjc-wid-50'">
													<dd class="qjc-c-primary">
														<b>{{ arrMax(item.person_rates) }}</b>%
													</dd>
													<dt>{{(res.base.package == 3 || res.base.package == 4) ? "未來一年最高患病概率" : res.base.package == 2 ? "未來三年最高患病概率" :"未來五年最高患病概率"}}</dt>
												</dl>
												<dl v-if="res.base.package != 4 && res.base.health_type != 1 && res.base.package != 3" class="qjc-fl qjc-wid-50">
													<dd class="qjc-c-primary">
														<div v-if="item.person_rates[0]>0 && item.compare_rates[0]>0">
															<b>{{ (item.person_rates[0]/item.compare_rates[0]).toFixed(2) }}</b>倍
														</div>
														<div v-else>
															<b>不適用</b>
														</div>
													</dd>
													<dt>是同族群平均概率的</dt>
												</dl>
											</div>
											<div class="symptom">
												<span class="qjc-c-dark qjc-ftw-b">主要徵狀：</span>
												{{ item.harm }}
											</div>
											<p class="qjc-texta-l"><span class="qjc-c-dark">註：</span>同族群（PLM，People Like Me）:與您相似的人群--與您同性別同年齡段的人群。</p>
										</li>
									</ul>
								</div>
								<div v-if="(res.base.health_type == 3 || res.base.health_type == 4) && res.m07.cancer_risk && res.m07.cancer_risk.length>0">
									<h3 class="qjc-c-dark">癌症風險</h3>
									<ul>
										<li v-for="(item, index) in res.m07.cancer_risk">
											<div class="illness-t">
												<b class="name qjc-c-dark">{{ index+1 }}.{{ item.devg }}</b>
												<span @click="chartOn('cancer', index, item, $event)" class="qjc-fr">
													查看詳細圖表
													<van-icon
														name="arrow-down"
													/>
												</span>
											</div>
											<div class="chart qjc-hide" :key="item.devg">
												
											</div>
											<div class="prob qjc-texta-c qjc-clearfix">
												<dl class="qjc-fl qjc-wid-100">
													<dd class="qjc-c-primary">
														<b>{{ arrMax(item.rates) }}</b>%
													</dd>
													<dt>未來二十年最高患病概率</dt>
												</dl>
											</div>
											<div class="symptom">
												<span class="qjc-c-dark qjc-ftw-b">主要徵狀：</span>
												{{ item.harm }}
											</div>
										</li>
									</ul>
								</div>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.noSick" @touchmove="checkMove($event)">
							<!-- 不患病&增寿 -->
							<div v-if="active == 'noSick' || nextActive == 'noSick'" class="noSick">
								<div>
									<h3 class="qjc-texta-l qjc-fts-36 qjc-c-dark">原發性疾病</h3>
									<ul>
										<li v-for="item in res.m08.filter((a)=>a.adagen_adj!=-999)">
											<h4 class="qjc-texta-l qjc-fts-32 qjc-c-dark">{{ item.devg }}</h4>
											<div class="qjc-clearfix">
												<dl class="qjc-wid-50 qjc-fl">
													<dd class="qjc-ftw-b qjc-c-dark">
														<b class="qjc-fts-32">{{ item.rr_desc }}</b>%
													</dd>
													<dt>最高風險概率</dt>
												</dl>
												<dl class="qjc-wid-50 qjc-fl">
													<dd class="qjc-ftw-b qjc-c-dark">
														<b class="qjc-fts-32">{{ item.maxage5 }}</b>歲
													</dd>
													<dt>疾病影響最高年歲</dt>
												</dl>
											</div>
											<p>不患病可增壽年數：<span class="qjc-c-primary"><b class="qjc-fts-32">{{ item.adagen_adj }}</b>年</span></p>
										</li>
									</ul>
								</div>
								<div>
									<h3 class="qjc-texta-l qjc-fts-36 qjc-c-dark">並發性疾病</h3>
									<ul>
										<li v-for="item in res.m08.filter((a)=>a.adagen_adj==-999)">
											<h4 class="qjc-texta-l qjc-fts-32 qjc-c-dark">{{ item.devg }}</h4>
											<div class="qjc-clearfix">
												<dl class="qjc-wid-50 qjc-fl">
													<dd class="qjc-ftw-b qjc-c-dark">
														<b class="qjc-fts-32">{{ item.rr_desc }}</b>%
													</dd>
													<dt>最高風險概率</dt>
												</dl>
												<dl class="qjc-wid-50 qjc-fl">
													<dd class="qjc-ftw-b qjc-c-dark">
														<b class="qjc-fts-32">{{ item.maxage5 }}</b>歲
													</dd>
													<dt>疾病影響最高年歲</dt>
												</dl>
											</div>
											<p>不患病可增壽年數：<span class="qjc-c-primary">--</span></p>
										</li>
									</ul>
								</div>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.improve" @touchmove="checkMove($event)">
							<!-- 健康管理和改善方案 -->
							<div v-if="active == 'improve' || nextActive == 'improve'" class="improve qjc-texta-l qjc-bg-fff">
								<div class="improve-body">
									<div class="target">
										<h3 class="qjc-fts-36 qjc-c-dark">體型改善目標</h3>
										<div>
											<dl>
												<dt>當前體重</dt>
												<dd class="qjc-c-dark qjc-ftw-b">
													<span>{{ res.m12.sugweight[0] }}</span>kg
												</dd>
											</dl>
											<van-image
												width="0.24rem"
												height="0.2rem"
												:src="require('@/assets/images/jiantouyou.png')"
											/>
											<dl>
												<dt>目標體重</dt>
												<dd class="qjc-c-primary qjc-ftw-b">
													<span>{{ res.m12.sugweight.slice(-1)[0] }}</span>kg
												</dd>
											</dl>
										</div>
										<div>
											<dl>
												<dt>當前腰臀比</dt>
												<dd class="qjc-c-dark qjc-ftw-b">
													<span>{{ res.m12.sugwhr[0] }}</span>
												</dd>
											</dl>
											<van-image
												width="0.24rem"
												height="0.2rem"
												:src="require('@/assets/images/jiantouyou.png')"
											/>
											<dl>
												<dt>目標腰臀比</dt>
												<dd class="qjc-c-primary qjc-ftw-b">
													<span>{{ res.m12.sugwhr.slice(-1)[0] }}</span>
												</dd>
											</dl>
										</div>
									</div>
									<div class="program">
										<h4 class="qjc-c-dark qjc-fts-32">建议改善方案</h4>
										<div class="program-res">
											<dl>
												<dt>計劃時長</dt>
												<dd class="qjc-texta-l">
													<b class="qjc-fts-32 qjc-c-dark">{{ res.m12.weeks.slice(-1)[0] }}</b>
													周
												</dd>
											</dl>
											<dl>
												<dt>平均每日飲食熱量</dt>
												<dd class="qjc-texta-c">
													<b class="qjc-fts-32 qjc-c-dark">{{ res.m14.bmr }}</b>
													大卡
												</dd>
											</dl>
											<dl>
												<dt>建議熱量改善目標</dt>
												<dd class="qjc-texta-c">
													<b class="qjc-fts-32 qjc-c-dark">{{ res.m14.c0 == '維持'?'維持':(res.m14.c0 == "增加"?'+'+res.m14.bmr_df:(res.m14.c0 == "減少"?res.m14.bmr_df:'')) }}</b>
													{{ res.m14.c0 != '維持'?'大卡/日':'' }}
												</dd>
											</dl>
											<dl>
												<dt>建議運動次數</dt>
												<dd class="qjc-texta-r">
													<b class="qjc-fts-32 qjc-c-dark">{{ res.m14.s1+res.m14.s2+res.m14.s3 }}</b>
													次/周
												</dd>
											</dl>
										</div>
										<div class="eat">
											<h5 class="qjc-fts-28 qjc-c-dark">飲食部分建議</h5>
											<p>說明：1、一份約一個成人拳頭或手掌大。2、一杯約500cc</p>
											<ul>
												<li>
													<div>
														<van-image
															width="0.3rem"
															height="0.28rem"
															:src="require('@/assets/images/zhushilei.png')"
														/>
														主食類
													</div>
													<b class="qjc-c-dark">
														<span>{{ res.m14.c0=='維持'?'維持':(res.m14.c0=='增加'?'+'+res.m14.c21:(res.m14.c0=='減少'?(res.m14.c21 == 0?'維持':'-'+res.m14.c21):'')) }}</span>{{ res.m14.c0!='維持' && res.m14.c21!=0?'碗':'' }}
													</b>
												</li>
												<li>
													<div>
														<van-image
															width="0.31rem"
															height="0.28rem"
															:src="require('@/assets/images/shucai.png')"
														/>
														蔬菜類
													</div>
													<b class="qjc-c-dark">
														<span>{{ res.m14.c0=='維持'?'+0.5':'+'+accMul(res.m14.c21,1.5) }}</span>份
													</b>
												</li>
												<li>
													<div>
														<van-image
															width="0.31rem"
															height="0.28rem"
															:src="require('@/assets/images/roulei.png')"
														/>
														肉類
													</div>
													<b class="qjc-c-dark">
														<span>{{ res.m14.c0=='維持'?'維持':(res.m14.c0=='增加'?'+'+res.m14.c12:(res.m14.c0=='減少'?(res.m14.c12 == 0?'維持':'-'+res.m14.c12):'')) }}</span>{{ res.m14.c0!='維持' && res.m14.c12!=0?'份':'' }}
													</b>
												</li>
												<li>
													<div>
														<van-image
															width="0.2rem"
															height="0.3rem"
															:src="require('@/assets/images/doulei.png')"
														/>
														豆類
													</div>
													<b class="qjc-c-dark">
														<span>{{ res.m14.c0=='維持'?'維持':(res.m14.c0=='增加'?'+'+res.m14.c31:(res.m14.c0=='減少'?(res.m14.c31 == 0?'維持':'-'+res.m14.c31):'')) }}</span>{{ res.m14.c0!='維持' && res.m14.c31!=0?'份':'' }}
													</b>
												</li>
												<li>
													<div>
														<van-image
															width="0.24rem"
															height="0.3rem"
															:src="require('@/assets/images/shuiguolei.png')"
														/>
														水果類
													</div>
													<b class="qjc-c-dark">
														<span>{{ res.m14.c0=='維持'?'維持':(res.m14.c0=='增加'?'+'+res.m14.c41:(res.m14.c0=='減少'?(res.m14.c41 == 0?'維持':'-'+res.m14.c41):'')) }}</span>{{ res.m14.c0!='維持' && res.m14.c41!=0?'份':'' }}
													</b>
												</li>
												<li>
													<div>
														<van-image
															width="0.27rem"
															height="0.3rem"
															:src="require('@/assets/images/ruzhilei.png')"
														/>
														乳脂類
													</div>
													<b class="qjc-c-dark">
														<span>{{ res.m14.c0=='維持'?'維持':(res.m14.c0=='增加'?'+'+res.m14.c51:(res.m14.c0=='減少'?(res.m14.c51 == 0?'維持':'-'+res.m14.c51):'')) }}</span>{{ res.m14.c0!='維持' && res.m14.c51!=0?'份':'' }}
													</b>
												</li>
											</ul>
										</div>
										<div class="sport" v-if="res.base.package != 3">
											<h4 class="qjc-fts-28 qjc-c-dark">運動部分建議</h4>
											<p>運動項目依強度，分為低強度、中強度與高強度，各強度運動項目可參考下列所 述，建議根據個人作息時間與身心負擔，進行適當的彈性調整。</p>
											<table class="qjc-wid-100" border="0" cellpadding="0" cellspacing="0">
												<tr>
													<th></th>
													<th>低強度運動</th>
													<th>中強度運動</th>
													<th>高強度運動</th>
												</tr>
												<tr>
													<td>增加次數</td>
													<td><span>{{ res.m14.s1 }}</span>次/周</td>
													<td><span>{{ res.m14.s2 }}</span>次/周</td>
													<td><span>{{ res.m14.s3 }}</span>次/周</td>
												</tr>
												<tr>
													<td>時長</td>
													<td><span>30</span>分鐘</td>
													<td><span>30</span>分鐘</td>
													<td><span>30</span>分鐘</td>
												</tr>
											</table>
											<div class="symptom">
												<p>低強度運動：如散步、做家事、逛街、遛狗等；</p>
												<p>中強度運動：如快步走、有氧運動、爬樓梯、跳舞等；</p>
												<p>高強度運動：如遊泳、打籃球、跑步、仰臥起坐、跳繩等。</p>
											</div>
										</div>
									</div>
								</div>
								<div class="improve-biom" v-if="res.base.package == 1">
									<h3 class="qjc-fts-36 qjc-c-dark">生化指標改善方案</h3>
									<p>生化指標會經常發生改變，代表著健康狀況和健康風險發生了變化。定期使用大數據進行分析，及時發現問題，預防疾病，掌握健康。</p>
									<p>根據您的健康狀況，我們建議您在短期內對生化指標進行適當改善，改善目標如下：</p>
									<div class="improve-table">
										<table class="qjc-wid-100" border="0" cellpadding="0" cellspacing="0">
											<thead>
												<td></td>
												<td>主要檢查項目</td>
												<td>檢查結果</td>
												<td>健康風險</td>
												<td>短期改善目標</td>
											</thead>
											<tr v-for="(item, index) in improveBiomData.CBC" :key="item.id">
												<td v-if="index==0" :rowspan="improveBiomData.CBC.length" class="left-side">血液常規</td>
												<td :class="{ 'qjc-ftw-b qjc-c-dark': item.score>2 }">{{ item.item_d }}</td>
												<td><b>{{ item.value }}</b> {{ item.unit }}</td>
												<td>
													<i v-for="i in item.score" :key="i" :class="{ 'iconxiaolian': item.score == 1,'iconbukubuxiaolian': item.score == 2,'iconkulian c-danger': item.score > 2 }" class="iconfont"></i>
												</td>
												<td>{{ item.score <= 2?item.target:item.target+item.unit }}</td>
											</tr>
											<tr v-for="(item, index) in improveBiomData.bloodGlucose" :key="item.id">
												<td v-if="index==0" :rowspan="improveBiomData.bloodGlucose.length" class="left-side">血糖</td>
												<td :class="{ 'qjc-ftw-b qjc-c-dark': item.score>2 }">{{ item.item_d }}</td>
												<td><b>{{ item.value }}</b> {{ item.unit }}</td>
												<td>
													<i v-for="i in item.score" :key="i" :class="{ 'iconxiaolian': item.score == 1,'iconbukubuxiaolian': item.score == 2,'iconkulian c-danger': item.score > 2 }" class="iconfont"></i>
												</td>
												<td>{{ item.score <= 2?item.target:item.target+item.unit }}</td>
											</tr>
											<tr v-for="(item, index) in improveBiomData.bloodLipids" :key="item.id">
												<td v-if="index==0" :rowspan="improveBiomData.bloodLipids.length" class="left-side">血脂肪</td>
												<td :class="{ 'qjc-ftw-b qjc-c-dark': item.score>2 }">{{ item.item_d }}</td>
												<td><b>{{ item.value }}</b> {{ item.unit }}</td>
												<td>
													<i v-for="i in item.score" :key="i" :class="{ 'iconxiaolian': item.score == 1,'iconbukubuxiaolian': item.score == 2,'iconkulian c-danger': item.score > 2 }" class="iconfont"></i>
												</td>
												<td>{{ item.score <= 2?item.target:item.target+item.unit }}</td>
											</tr>
											<tr v-for="(item, index) in improveBiomData.ALT" :key="item.id">
												<td v-if="index==0" :rowspan="improveBiomData.ALT.length" class="left-side">肝膽功能</td>
												<td :class="{ 'qjc-ftw-b qjc-c-dark': item.score>2 }">{{ item.item_d }}</td>
												<td><b>{{ item.value }}</b> {{ item.unit }}</td>
												<td>
													<i v-for="i in item.score" :key="i" :class="{ 'iconxiaolian': item.score == 1,'iconbukubuxiaolian': item.score == 2,'iconkulian c-danger': item.score > 2 }" class="iconfont"></i>
												</td>
												<td>{{ item.score <= 2?item.target:item.target+item.unit }}</td>
											</tr>
											<tr v-for="(item, index) in improveBiomData.renalFunc" :key="item.id">
												<td v-if="index==0" :rowspan="improveBiomData.renalFunc.length" class="left-side">腎功能</td>
												<td :class="{ 'qjc-ftw-b qjc-c-dark': item.score>2 }">{{ item.item_d }}</td>
												<td><b>{{ item.value }}</b> {{ item.unit }}</td>
												<td>
													<i v-for="i in item.score" :key="i" :class="{ 'iconxiaolian': item.score == 1,'iconbukubuxiaolian': item.score == 2,'iconkulian c-danger': item.score > 2 }" class="iconfont"></i>
												</td>
												<td>{{ item.score <= 2?item.target:item.target+item.unit }}</td>
											</tr>
										</table>
									</div>
									<div class="symptom">
										<i class="iconfont iconxiaolian"> ：良好</i>
										<i class="iconfont iconbukubuxiaolian"> ：一般</i>
										<i class="iconfont iconkulian c-danger"> ：需要改善</i>
									</div>
								</div>
							</div>
						</van-swipe-item>
					</van-swipe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Grid, GridItem, Image, Icon, Sticky, Toast, Swipe, SwipeItem } from 'vant'
	Vue.use(NavBar)
	   .use(Grid)
	   .use(GridItem)
	   .use(Image)
	   .use(Icon)
	   .use(Sticky)
	   .use(Toast)
	   .use(Swipe)
	   .use(SwipeItem);
	
	import { resultCommit } from '@/js/axios.js'
	import { accMul } from '@/js/utils.js'
	var echarts = require('echarts');
	
	export default{
		name: 'result',
		data (){
			return {
				id: '',//報告id
				c: null,//报告修改次数 只能修改一次
				active: 'biom',//当前展示模块(与class名对应) 默認第一個生化指標
				nextActive: '',//另一个展示模块(左右切换的另一个模块,避免出现空白)
				offsetTop: 0,//模块列表吸顶距离 默认为0
				res: {
					base: {
						package:'',
						health_type: '',//所属人群
						height: '',//身高
						weight: '',//體重
						fat: '',//體脂率
						// bust: '',//胸圍
						waist: '',//腰圍
						hip: '',//臀圍
						sex: ''//男女 1男 2女
					},
					m01: {//总体健康评估和生命预测
						pre_lift: '',//預期壽命
						lift_rates: [],//百岁概率
						pre_rem: '',//年龄区间
						rates_ages: [],//年龄
						heal_rates: [],//健康率
						lost_heal_ratio: []//失能率
					},
					m05: {//体型评估
						body_g: [],//体型评估总结果
						bmi_g: [],//bmi
						whr_g: [],//whr
						fat_g: []//bfr
					},
					m06: {},//生化指标评估表格数据
					m07: {//未来疾病风险预测
						dis_risk: [],//疾病列表
						cancer_risk: []//癌症列表(医疗需求者可能会有)
					},
					m08: [],//不患病&增寿
					m10: {//医疗费用预测
						person_allfee: '',//终生医疗费用预估
						person_yearsfee: [],//未来十年医疗费用预估
						compare_yearsfee: []//未来十年同族群医疗费用预估
					},
					m12: {//健康管理和改善方案
						sugweight: [],//当前体重取[0] 目标体重取最后一个
						sugwhr: [],//当前腰臀比去[0] 目标腰臀比去最后一个
						weeks: []//计划时长
					},
					m14: {//健康管理和改善方案
						bmr: '',//平均每日饮食热量
						c0: '',//判断当前维持状态
						c21: '',//主食类
						c12: '',//肉类
						c31: '',//豆类
						c41: '',//水果类
						c51: ''//乳脂类
					},
					m15: []//生化指标评估：常见疾病风险倍率
				},//数据结构 和后台传输数据保持一致
				biomData: {
					CBC: [],//血液常规
					bloodGlucose: [],//血糖
					bloodLipids: [],//血脂肪
					ALT: [],//肝胆功能
					renalFunc: []//肾功能
				},//生化指标评估数据
				improveBiomData: {
					CBC: [],//血液常规
					bloodGlucose: [],//血糖
					bloodLipids: [],//血脂肪
					ALT: [],//肝胆功能
					renalFunc: []//肾功能
				},//改善生化指标评估数据
				BMIGroup: false,//小提示
				WHRGroup: false,//小提示
				BFRGroup: false,//小提示
				start: 0,//左右切换touch初始x位置
				startY: 0,//左右切换touch初始Y位置
				BMIValue: '',
				WHRValue: '',
				BFRValue: '',
				BFRValue1: '',
				BFRValue2: '',
				BMISpac: '',
				BFRSpac: '',
			}
		},
		computed: {
			modules (){//当前结果页所拥有模块
				var list = {
					biom: this.res.base.package != 3,//生化指標評估
					health: this.res.base.health_type != 3 && this.res.base.package != 3,//未來健康風險預測(尊享非医疗需求者会有)
					total: this.res.base.package != 4,//总体健康评估和生命预测（疫境求真没有）
					bodily: this.res.base.package != 4,//体型评估（疫境求真没有）
					costs: true,//医疗费用预测
					illness: true,//未来疾病风险预测
					noSick: this.res.base.health_type != 3 && (this.res.base.package == 1 || this.res.base.package == 4),//不患病&增寿(尊享非医疗需求者会有)
					improve: this.res.base.package != 4//健康管理和改善方案（疫境求真没有）
				};
				return list;
			}
		},
		watch:{
			$route (){
				this.id = this.$route.params.id;
			},// 路由变化更新id
			id (){
				resultCommit(this.id).then( data => {
					if(data.status == 1){
						var result = JSON.parse(window.atob(data.data));
						console.log(result);
						result.base.package = 2; // 疫境求真套餐模板结果页使用精英模板输出 强制转换
						if(result.base.package == 4){// 疫境求真
							// 去除同族群(部分在结构中判断不显示)
							result.m10.compare_yearsfee = [];
							for(var i=0;i<result.m06.length;i++){
								result.m06[i].group_p = '--';
							}
							// 医疗费用未来三年
							result.m10.person_yearsfee = result.m10.person_yearsfee.slice(0 ,3);
							// 未来疾病显示三种
							result.m07.dis_risk = result.m07.dis_risk.slice(0, 3);
						}
						
						this.BMIValue = result.m05.bmi_n[2].split('~')
						this.WHRValue = result.m05.whr_n[2].split('<')
						this.BFRValue = result.m05.fat_n[2].split('~')
						this.BFRValue1 = Number(this.BFRValue[0].substr(0,2))
						this.BFRValue2 = Number(this.BFRValue[1].substr(0,2))
						this.BMISpac = this.BMIValue[1] - this.BMIValue[0]
						this.BFRSpac = this.BFRValue2 - this.BFRValue1
						
						this.res = result;
						
						//顯示當前模塊圖表
						this.showChart();
						if(this.res.base.package == 3){
							this.checkModule('total');
						}
					}else{
						Toast.fail(data.msg);
						this.$router.back();
					}
				})
			}// id变化,重新获取数据
		},
		mounted (){
			// 初次进入回到顶部
			window.scrollTo(0,0);

			document.title = '評估結果';
			this.id = this.$route.params.id;// 当前结果id

			// 模块列表吸顶距离
			this.$nextTick(() => {
				this.offsetTop = document.querySelector('html').style.fontSize.replace('px','')*0.88;
				
				//切换模块完成后合并为一个,否则页面高度和切换可能会出现问题
				document.querySelector('.van-swipe__track').addEventListener('transitionend', () => {
					this.nextActive = this.active;
				});
			});
		},
		methods: {
			checkModule (module){
				this.active = module;
				window.scrollTo(0,(document.querySelector('.list').offsetTop - document.querySelector('.list-title').offsetHeight));
				this.showChart();
				
				this.$refs.swp.swipeTo(this.getModules().indexOf(module));
			},//切换模块列表
			
			clickCheckModule (module){
				this.nextActive = this.active;
				this.checkModule(module)
			},//點擊模塊名切換模塊
			
			slide (index){
				this.checkModule(this.getModules()[index]);
			},//滑动切换模块
			
			touchStart (e){
				this.nextActive = this.active;
				this.start= e.changedTouches[0].pageX;
				this.startY= e.changedTouches[0].pageX;
			},
			
			checkMove (e){
				let move = e.changedTouches[0].pageX,
					arr = this.getModules(),
					nowIndex = arr.indexOf(this.nextActive),
					nexIndex;
				
				//是否是上下滑动(防止上下滑动触发左右滑动事件) 上下滑动超过10单位定位上下滑动
				let upDown = Math.abs(e.changedTouches[0].screenY-this.startY)>10?true:false;
				if(!upDown){
					if(this.start>move && nowIndex<arr.length-1){
						nexIndex = nowIndex+1;
					}else if(this.start<move && nowIndex>0){
						nexIndex = nowIndex-1;
					}else{
						nexIndex = nowIndex;
					}
					
					this.active = arr[nexIndex];
				}
			},//判断左滑动还是右滑动,便于显示下一模块,使切换更平滑
			
			getModules (){
				let arr = [];
				for(let i in this.modules){
					this.modules[i]?arr.push(i):'';
				}
				return arr;
			},//获取当前所有模块名称数组
			
			edit (){
				if(this.res.c>0){
					Toast.fail('只能修改一次哦');
				}else{
					sessionStorage.setItem('editQuestionID',this.id);
					this.$router.push('/editQuestion');
				}
			},//修改结果(一次机会)
			
			chartOn (type, index, item, e){
				var chartDom;
				if(e.target.className.indexOf('van-icon') != -1){
					chartDom = e.target.parentNode.parentNode.nextElementSibling;
				}else {
					chartDom = e.target.parentNode.nextElementSibling;
				}
				if(chartDom.className.indexOf(type) == -1){
					chartDom.className += ' '+type+index;
				}
				if(chartDom.className.indexOf('qjc-hide') == -1){
					chartDom.className += ' qjc-hide';
				}else{
					chartDom.className = chartDom.className.replace('qjc-hide','');
				}
				if(type == 'risk'){//疾病
					this.illnessChart('.'+type+index, item.person_rates, item.compare_rates);
				}else if(type == 'cancer'){ //癌症
					this.cancerChart('.'+type+index, item.devg, item.rates);
				}
			},//未来疾病展示图表 参数1：当前所属图表(疾病或癌症)
			
			arrMax (arr){
				var max = arr[0];//默认取第一个
				for(var i=1;i<arr.length;i++){//从第二个开始筛选
					arr[i]>max?max=arr[i]:'';
				}
				return max;
			},//数组中最大值
			
			accMul,
			
			//生化指标表格數據篩選分類 參數：當前所在模塊
			biomClassify (module){
				var CBC = ['WBC','HGB','PLT'],//血液常规
					bloodGlucose = ['FG','HBA1C'],//血糖
					bloodLipids = ['CHOL','LDLC','HDLC','TG'],//血脂肪
					ALT = ['GOT','GPT','ALP','ALB'],//肝胆功能
					renalFunc = ['CRE','UA','EGFR'];//肾功能
				
				var nowData,data;
				if(module == 'biom'){//生化指標評估模塊
					nowData = this.biomData = {
						CBC: [],//血液常规
						bloodGlucose: [],//血糖
						bloodLipids: [],//血脂肪
						ALT: [],//肝胆功能
						renalFunc: []//肾功能
					};
					data = this.res.m06;
				}else{//生化指標改善模塊 
					nowData = this.improveBiomData = {
						CBC: [],//血液常规
						bloodGlucose: [],//血糖
						bloodLipids: [],//血脂肪
						ALT: [],//肝胆功能
						renalFunc: []//肾功能
					};
					data = this.res.m13;
				}
				
				for(var i=0;i<data.length;i++){
					if(CBC.indexOf(data[i].item) != -1){
						nowData.CBC.push(data[i]);
					}else if(bloodGlucose.indexOf(data[i].item) != -1){
						nowData.bloodGlucose.push(data[i]);
					}else if(bloodLipids.indexOf(data[i].item) != -1){
						nowData.bloodLipids.push(data[i]);
					}else if(ALT.indexOf(data[i].item) != -1){
						nowData.ALT.push(data[i]);
					}else if(renalFunc.indexOf(data[i].item) != -1){
						nowData.renalFunc.push(data[i]);
					}
				}
			},
			
			//未來健康風險預測-亞健康(三高)數據篩選分類
			threeHighsClassify (){
				var three = ['A01','A02','A16'];
				return this.res.m15.filter(item => three.indexOf(item.dis) != -1);
			},
			
			// 當前模塊顯示圖表
			showChart (){
				this.$nextTick(() => {
					switch (this.active){
						case 'biom': //常見疾病風險倍率
							this.biomClassify(this.active);//生化指標評估表格
							this.BiochemicalChart(this.res.m15);
							break;
						
						case 'total': //總體健康評估和生命預測
							this.totalChart(this.res.m01);
							break;
							
						case 'costs': //醫療費用預測
							this.costsChart(this.res.m10.person_yearsfee,(this.res.base.package != "3" && this.res.base.health_type != "1") ? this.res.m10.compare_yearsfee : []);
							break;
						case 'improve': //健康管理和改善方案
							this.res.base.package == 1 ? this.biomClassify(this.active) : '';//改善生化指標
							break;
					}
				})
			},
			
			// 圖表列表
			BiochemicalChart (data){
				var option = {
					grid: {
						top: 60,
						bottom: 30,
						left: '22%',
						right:'15%'
					},
					legend:{
						show:false
					},
					xAxis: {
						type: 'value',
						show: false,
						splitLine: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						}
					},
					yAxis: [
						{
							name: '疾病類別',
							boundaryGap: false,
							nameGap: 28,
							offset: 0,
							nameTextStyle: {
								color: '#777F8F'
							},
							type: 'category',
							axisTick: {
								show: false
							},
							splitLine: {
								show: false
							},
							data: []
						},{
							name: '患病风险倍率',
							boundaryGap: false,
							nameGap: 28,
							nameTextStyle: {
								color: '#777F8F'
							},
							type: 'category',
							axisTick: {
								show: false
							},
							splitLine: {
								show: false
							},
							axisLine:{
								show:false
							},
							data: []
						}
					],
					visualMap: {
						type:'piecewise',
						orient: 'horizontal',
						left: 'center',
						min: 10,
						max: 100,
						show:false,
						pieces: [{
							gt: 0,
							lte: 1,
							color: '#65D448'
						}, {
							gt: 1,
							color: '#F25364'
						},],
						// Map the score column to color
						dimension: 0,
					},
					series: [
						{
							type: 'bar',
							barWidth: 6,
							itemStyle: {
								color: '#65D448'
							},
							label:{
								show:false
							},
							markLine: {
								silent: true,
								label: {
									formatter: '1.0'
								},
								symbol: 'none',
								data: [{
									xAxis:1
								}],
								lineStyle:{
									color:'#F25364'
								}
							},
							data: []
						}
					]
				}
				// 风险倍率从大到小排序
				data.sort((a,b) => a.rate-b.rate);
				//超出1.0疾病类型字体颜色变深
				for(var i=0;i<data.length;i++){
					option.yAxis[1].data.push(data[i].rate);// 疾病类型倍率数组
					option.series[0].data.push(data[i].rate);// 疾病风险倍率
					var yName = {
						value: data[i].name,
						textStyle: {
							color: data[i].rate>1?'#40444D':'#777F8F',
							fontWeight: data[i].rate>1?'bold':'normal'
						}
					};
					option.yAxis[0].data.push(yName);
				}
				this.drawChat('.biom-chart', option);
			},//常见疾病风险倍率
			
			totalChart (data){
				//取当前年龄后一年到预期寿命年限的生命曲线数据
				var section = Math.round(data.pre_rem);// 年龄区间
				data.rates_ages = data.rates_ages.slice(0,section);// 年龄
				data.heal_rates = data.heal_rates.slice(0,section);// 健康率
				data.lost_heal_ratio = data.lost_heal_ratio.slice(0,section);// 失能率
				
				var option = {
					title: {
						text: '在未來'+section+'年間，您的生命曲線',
						textStyle: {
							color: '#40444D',
							fontSize: 14,
							fontWeight: '400'
						}
					},
					legend: {
						data: ['健康率','失能率'],
						icon: 'circle',
						textStyle: {
							color: '#777F8F',
							fontSize: 12
						},
						top: 2,
						right: 0,
						itemWidth: 6,
						itemHeight: 6,
						itemGap: 6
					},
					grid: {
						left: 40,
						bottom: 30
					},
					color: ['#6883FB','#F95A6B'],
					backgroundColor: '#fff',
					tooltip: {
						trigger: 'axis',
						formatter: '{b}<br>{a}: {c}%<br>{a1}: {c1}%',
						backgroundColor: '#6883FB',
						extraCssText: 'text-align: left;'
					},
					xAxis: {
						type: 'category',
						axisLine: {
							show: false
						},
						axisLabel: {
							color: '#777f8f',
							fontWeight: '300'
						},
						boundaryGap: false,
						axisTick: {
							show: false
						},
						nameLocation: 'start',
						data: (function(){
							for(var i=0,ages=[];i<data.rates_ages.length;i++){
								ages.push(data.rates_ages[i]+'歲');
							}
							return ages;
						})()
					},
					yAxis: {
						type: 'value',
						axisLine: {
							show: false
						},
						axisLabel: {
							formatter: '{value}%',
							color: '#777f8f',
							fontWeight: '300'
						},
						axisTick: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#E7E9EE'
							}
						}
					},
					series: [
						{
							name: '健康率',
							type: 'line',
							data: data.heal_rates,
							lineStyle: {
								width: 3
							},
							symbol: 'none',
							smooth: true
						},
						{
							name: '失能率',
							type: 'line',
							data: data.lost_heal_ratio,
							lineStyle: {
								width: 3
							},
							symbol: 'none',
							smooth: true
						}
					]
				}
				this.drawChat('.total-chart', option);
			},// 总体健康评估和生命预测
			
			costsChart (tenYears, tenYearsGroup){
				var option = {
					title: {
						text: this.res.base.package == "1" ? '未來十年主要醫療費用演化趨勢' : this.res.base.package == "2" ? '未來五年主要醫療費用演化趨勢':'未來三年主要醫療費用演化趨勢',
						textStyle: {
							color: '#40444D',
							fontSize: 12
						},
						bottom: 15,
						left: 'center'
					},
					legend: {
						data: ['您的累計醫療費用預估','同族群的累計醫療費用預估'],
						icon: 'roundRect',
						textStyle: {
							color: '#777F8F',
							fontSize: 12
						},
						top: 10,
						right: 0,
						itemWidth: 10,
						itemHeight: 4,
						itemGap: 5,
						orient: 'vertical'
					},
					backgroundColor: '#fff',
					grid: {
						left: '18%',
						right: 0
					},
					tooltip: {
						trigger: 'axis',
						formatter: '第{b0}年',
						backgroundColor: '#FCFDFF',
						confine: true,
						textStyle: {
							color: '#666',
							fontSize: 12,
							fontWeight: 300
						},
						extraCssText: 'box-shadow: 0px 10px 20px 0px rgba(136,144,152,0.14);text-align: left;'
					},
					xAxis: {
						type: 'category',
						nameTextStyle: {
							color: '#777F8F'
						},
						axisLabel: {
							color: '#777F8F'
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						data: (() => {
							var x = [];
							for(var i=0;i<tenYears.length;i++){
								x.push(i+1);
							}
							return x;
						})()
					},
					yAxis: {
						name: '$      ',
						nameTextStyle: {
							color: '#777F8F'
						},
						axisLabel: {
							color: '#777F8F'
						},
						axisLine: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#f5f5f5'
							}
						},
						axisTick: {
							show: false
						},
					},
					series: [
						{
							type: 'bar',
							name: '您的累計醫療費用預估',
							itemStyle: {
								color: new echarts.graphic.LinearGradient(
								  0, 0, 0, 1,//渐变色在下面修改，这里是透明度
								  [{
									  offset: 0,
									  color: '#98ABFF'
									},
									{
									  offset: 1,
									  color: '#758EFF'
									}
								  ]
								)
							},
							barWidth: 8,
							data: []
						},
						{
							type: 'bar',
							name: '同族群的累計醫療費用預估',
							itemStyle: {
								normal: {
									color: '#E6E8EB'
								},
								emphasis: {
									color: '#E6E8EB',
									opacity: '0.8'
								}
							},
							barWidth: 8,
							data: []
						}
					]
				}
				// 是否有同族群展示
				if(tenYearsGroup){

				}
				if(tenYearsGroup.length<1){
					option.legend.data.pop();
					option.series.pop();
					option.tooltip.formatter += '<br>{a0}:{c0}元';
				}else{
					for(var p=0;p<tenYearsGroup.length;p++){
						// 费用计算
						option.series[1].data.push(tenYearsGroup[p]);
					}
					option.tooltip.formatter += '<br>{a0}:{c0}元<br>{a1}:{c1}元';
				}
				for(var q=0;q<tenYears.length;q++){
					// 费用计算
					option.series[0].data.push(tenYears[q]);
				}
				this.drawChat('.costs-chart', option);
			},// 医疗费用预测  参数一：十年费用预测  参数二：同族费用预测(可能为空)
			
			illnessChart (el, person, compare){
				var option = {
						tooltip: {
							trigger: 'axis',
							formatter: '{b}'
						},
						legend: {
							data: ['個人患病概率','同族群患病概率'],
							icon: 'roundRect',
							textStyle: {
								color: '#777F8F',
								fontSize: 12
							},
							itemWidth: 10,
							itemHeight: 4,
							itemGap: 20
						},
						xAxis: {
							boundaryGap: false,
							axisLabel: {
								color: '#777F8F'
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								show: 'true',
								lineStyle: {
									color: '#f5f5f5'
								}
							},
							data: (function(){
								var arr = [];
								for(var i=0;i<person.length;i++){
									arr.push('第'+(i+1)+'年');
								}
								return arr;
							})()
						},
						yAxis: {
							name: '%',
							type: 'value',
							axisLabel: {
								color: '#777F8F'
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								lineStyle: {
									color: '#f5f5f5'
								}
							}
						},
						series: [
							{
								name: '個人患病概率',
								type: 'line',
								data: person
							},
							{
								name: '同族群患病概率',
								type: 'line',
								data: []
							}
						],
						color: ['#6883FB','#65D448']
					};
				option.tooltip.formatter += '<br>個人患病概率:{c}%';
				if(this.res.base.package != 4 && compare.length>1){
					option.tooltip.formatter += '<br>同族群患病概率:{c1}%';
					option.series[1].data = compare;
				}else{
					option.legend.data.pop();
					option.series.pop();
				}
				this.drawChat(el, option);
			},//未来疾病风险预测 参数：1：疾病列表下标(用了区分图标class) 2:疾病名称 3：疾病列表数据 4：同族群列表数据
			
			cancerChart (el,name,data){
				var option = {
					tooltip: {
						trigger: 'axis',
						formatter: '未來{b}年的疾病風險<br>您患'+name+'的概率為:{c}%'
					},
					xAxis: {
						axisLabel: {
							color: '#777F8F'
						},
						boundaryGap: false,
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: 'true',
							lineStyle: {
								color: '#f5f5f5'
							}
						},
						data: ['5年','10年','15年','20年']
					},
					yAxis: {
						name: '%',
						type: 'value',
						axisLabel: {
							color: '#777F8F'
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#f5f5f5'
							}
						}
					},
					series: [
						{
							type: 'line',
							symbol: 'none',
							areaStyle: {
								opacity: .2,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
								        offset: 0, color: '#6883FB'
								    },  {
								        offset: 1, color: '#fff' 
								    }]
								)
							},
							data: data
						}
					],
					color: ['#6883FB','#65D448']
				}
				this.drawChat(el, option);
			},//癌症风险图表
			
			drawChat(el, option){
				window[el] = echarts.init(document.querySelector(el));
				// option.animationDuration = 1000;
				window[el].setOption(option,true);
				window.onresize = window[el].resize;
			}// 繪製圖表通用
		}
	}
</script>

<style scoped>
	.infomation >>> .van-grid-item__content{
		height: 0.56rem;
		text-align: left;
		padding: 0;
		background-color: #F5F6FA;
		border-radius: 0.28rem;
	}
	
	.person >>> .van-grid-item__content{
		padding: 0;
	}
	
	.total >>> .van-grid-item__content{
		background: #F5F6FA;
		border-radius: 0.08rem;
		height: 2.26rem;
		padding: 0.24rem 0.2rem;
	}
</style>
<style lang="scss" scoped>
	@import "../../assets/iconfont/iconfont.css";
	
	.van-nav-bar{
		background-color: #F5F7FA;
	}
	.result{
		padding-top: 0.88rem;
		color: #777F8F;
		// 个人信息
		.infomation{
			padding: 0.48rem 0 0.8rem;
			background: url(../../assets/images/di.png) no-repeat;
			background-size: 100% 100%;
			
			.danger{
				width: 2.51rem;
				height: 2.24rem;
				margin: 0 auto;
				padding-top: 0.8rem;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				
				&.jiankang{
					background-image: url(../../assets/images/jiankang.png);
				}
				&.yajiankang{
					background-image: url(../../assets/images/yajiankang.png);
				}
				&.yiliaoxuqiu{
					background-image: url(../../assets/images/yiliaoxuqiu.png);
				}
				
				.danger-val{
					font-size: 0.48rem;
				}
				dt{
					font-size: 0.2rem;
					margin-top: 0.3rem;
				}
			}
			.van-grid{
				margin-top: 0.56rem;
				padding: 0 0.3rem;
				
				.van-grid-item{
					padding: 0.08rem 0.08rem;
					overflow: auto;
				}
			}
			.hint{
				font-size: 0.2rem;
				color: #777F8F;
				margin-top: 0.06rem;
			}
		}
		.person{
			margin-top: -0.4rem;
			padding: 0 0.1rem;
			
			dl{
				margin: 1rem auto;
				
				dt{
					color: #999999;
				}
				b{
					font-size: 0.42rem;
				}
			}
		}
		// 结果列表
		.list{
			background-color: #F5F7FA;
			
			//滑动切换
			.van-swipe{
				.van-swipe-item{
					min-height: 1px;
				}
			}
			
			// 生化指標評估
			.biom{
				padding: 0.24rem;
				
				p{
					font-size: 0.2rem;
					color: #6A7180;
					line-height: 0.27rem;
					margin-bottom: 0.15rem;
				}
				table{
					font-size: 0.2rem;
					line-height: 0.28rem;
					border-radius: 0.08rem;
					border: 0.01rem solid #D3D5DE;
					overflow: hidden;
					
					.group-v{
						position: relative;
						
						&::before{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 2000%;
							border-left: 0.01rem dashed #D3D5DE;
							content: '';
						}
					}
					th,li{
						height: 0.67rem;
						padding: 0.2rem 0.15rem;
					}
					li{
						display: flex;
						align-items: center;
						span{
							font-weight: 600;
							margin-right: 0.05rem;
						}
					}
					tr>td:nth-child(2){
						width: 1.7rem;
					}
					tr>td:nth-child(4),tr>td:nth-child(5){
						width: 1.3rem;
					}
					tr>td:first-child{
						width: 0.45rem;
						padding: 0 0.12rem;
						border-right: 0.01rem solid #D3D5DE;
					}
					tr>td:last-child{
						width: 1.7rem;
						padding-left: 0.15rem;
					}
					tr>th:last-child{
						padding-left: 0.3rem;
					}
					tr:nth-child(even){
						background-color: #F5F8FC;
						
						td{
							border-top: 0.01rem solid #D3D5DE;
							border-bottom: 0.01rem solid #D3D5DE;
							
							&:first-child{
								border-left: 0.01rem solid #D3D5DE;
							}
							&:last-child{
								border-right: 0.01rem solid #D3D5DE;
							}
						}
					}
					.c-low{
						font-weight: 500;
						color: #3AC316;
					}
					.c-high{
						font-weight: 500;
						color: #FF3131;
					}
					.c-nice{
						font-weight: 500;
						color: #3AC316;
					}
					.qjc-c-primary{
						font-weight: 500;
					}
				}
				.chart-t{
					margin: 0.4rem 0 0.24rem;
				}
				.biom-chart{
					height: 7.925rem;
					background-color: #F5F8FC;
					border-radius: 0.08rem;
				}
			}
					
			// 未來健康風險預測
			.health{
				li{
					padding: 0.24rem;
					border-bottom: 0.1rem solid #F5F7FA;
					
					h3{
						margin: 0.2rem 0 0.3rem;
					}
					dl{
						background-color: #F5F8FC;
						border-radius: 0.08rem;
						padding: 0.3rem 0;
						line-height: 0.4rem;
						
						dd{
							span{
								font-size: 0.34rem;
							}
						}
					}
					p{
						margin: 0.24rem 0;
						font-weight: 300;
						line-height: 0.48rem;
						
						span{
							font-weight: 400;
						}
					}
				}
				// 健康者
				.health1{
					padding: 0.36rem 0.26rem;
					
					p{
						font-size: 0.28rem;
						color: #6A7180;
						font-weight: 300;
						line-height: 0.56rem;
						margin-bottom: 0.4rem;
					}
					div{
						display: flex;
						justify-content: space-between;
						background-color: #F5F8FC;
						padding: 0.22rem 0.5rem;
						margin-bottom: 0.28rem;
						
						dl{
							width: 1.9rem;
							border-radius: 0.08rem;
							
							b{
								font-size: 0.48rem;
							}
							dt{
								line-height: 0.28rem;
								font-weight: 500;
							}
						}
					}
				}
			}
					
			// 标题列表
			.list-title{
				height: 0.75rem;
				line-height: 0.75rem;
				display: flex;
				justify-content: flex-start;
				border-bottom: 0.01rem solid #D3D5DE;
				background-color: #F5F7FA;
				overflow-x: auto;
				
				li{
					padding: 0 0.2rem;
					position: relative;
					transition: all .3s;
					
					&::after{
						content: '';
						display: block;
						width: 0;
						height: 0.06rem;
						position: absolute;
						left: 50%;
						bottom: 0;
						background: #6681FA;
						transition: all .3s;
					}
					&.active{
						font-weight: bold;
						font-size: 0.28rem;
						
						&::after{
							width: 0.4rem;
							margin-left: -0.2rem;
						}
					}
				}
			}
			
			// 总体健康和生命预测
			.total{
				padding: 0.36rem 0.1rem 0.7rem;
				
				.total-t{
					.van-grid-item{
						p{
							margin-bottom: 1rem;
							height: 0.24rem;
							line-height: 0.24rem;
							
							b{
								font-weight: 400;
							}
						}
						div{
							display: flex;
							justify-content: space-between;
							align-items: center;
							
							span{
								font-size: 0.4rem;
							}
						}
					}
				}
				.total-chart{
					height: 6.43rem;
					margin: 0.5rem 0.1rem;
				}
				.hint{
					padding: 0 0.1rem;
					font-weight: 400;
					line-height: 0.48rem;
					
					.qjc-c-primary{
						font-weight: 500;
					}
				}
			}
			
			// 体型评估
			.bodily{
				padding: 0 0.24rem 0.8rem;
				background-color: #F5F7FA;
				
				h4{
					margin: 0.4rem 0 0.24rem;
					font-weight: 500;
				}
				.res{
					table{
						padding: 0.2rem 0.3rem;
						border-radius: 0.08rem;
						color: #6A7180;
						
						thead{
							line-height: 0.98rem;
							
							th{
								font-weight: 400;
								border-bottom: 0.01rem solid #777F8F;
								
								&.qjc-ftw-b{
									font-weight: 500;
								}
								&:first-child{
									width: 1rem;
									position: relative;
								}
							}
							.BFR-t{
								position: absolute;
								left: 0;
								bottom: 8%;
								text-align: left;
								width: 0.6rem;
								line-height: 0.26rem;
							}
							.BMI-t{
								position: absolute;
								top: 8%;
								right: 0;
								line-height: 0.26rem;
								
								&:before{
									content: '';
									position: absolute;
									left: -54%;
									bottom: 0;
									transform: rotateZ(45deg);
									width: 0.5rem;
									border-bottom: 0.01rem solid #777F8F;
								}
							}
						}
						tr{
							height: 1.77rem;
						}
						tr>td:first-child,
						thead>th:first-child{
							border-right: 0.01rem solid #777F8F;
						}
						.van-image{
							vertical-align: middle;
							margin-right: 0.1rem;
						}
						.img-hint{
							display: inline-block;
							font-size: 0.2rem;
							width: 0.2rem;
							line-height: 0.28rem;
							vertical-align: middle;
						}
					}
				}
				.targets{
					&>div{
						padding: 0.40rem 0.3rem 0.48rem;
						border-radius: 0.08rem;
						margin-bottom: 0.2rem;
					}
					[class^=hint]{
						height: 0.38rem;
						line-height: 0.38rem;
						padding: 0 0.1rem;
						text-align: center;
						color: #fff;
						font-size: 0.2rem;
						vertical-align: bottom;
						margin-left: 0.12rem;
						border-radius:0 0.15rem 0.15rem 0;
					}
					.hint-m{
						background-color: #2EE68D;
					}
					.hint-l{
						background-color: #338FFF;
					}
					.hint-h{
						background-color: #FF4D4D;
					}
					.targets-t{
						font-size: 0.38rem;
						line-height: 0.38rem;
						font-weight: bold;
						color: #250A0A;
						margin-bottom: 0.11rem;
					}
					.targets-t-h{
						font-size: 0.28rem;
						font-weight: 300;
						line-height: 0.56rem;
						color: #6A7180;
					}
					.iconyiwen1{
						color: #B8C0CC;
						font-size: 0.3rem;
						
						&>div{
							color: #777F8F;
							font-size: 0.24rem;
							bottom: 2em;
							left: -2.05rem;
							width: 4.2rem;
							margin-top: -0.2rem;
							padding: 0.24rem;
							line-height: 0.36rem;
							box-shadow:0 0.06rem 0.12rem 0 rgba(218,220,227,1);
							border-radius: 0.08rem;
							z-index: 9999;
							
							.arrow{
								position: absolute;
								bottom: -0.2rem;
								left: 50%;
								z-index: -1;
								width: 0.4rem;
								height: 0.4rem;
								background-color: #fff;
								transform: rotateZ(45deg)translateX(-50%);
							}
						}
					}
					.remark{
						font-size: 0.24rem;
						line-height: 0.48rem;
						color: #6A7180;
						font-weight: 300;
						margin-top: 0.56rem;
					}
					dt{
						margin: 0.56rem 0 0.3rem;
						font-size: 0.32rem;
						font-weight: 500;
						color: #333;
					}
					.group dt{
						margin-top: 0.48rem;
					}
					.line{
						position: relative;
						width: 6.3rem;
						height: 0.48rem;
						
						ul,ol{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							
							li{
								position: relative;
								height: 0.06rem;
								z-index: 1;
								
								&:not(:last-child){
									margin-right: 0.06rem;
								}
							}
						}
						ul{
							span{
								position: absolute;
								right: 0;
								bottom: -0.5rem;
								transform: translateX(50%);
							}
							li{
								&:nth-child(1){
									background-color: #338FFF;
								}
								&:nth-child(2){
									background-color: #2ee68d;
								}
								&:nth-child(3){
									// background-color: #ffbb00;
									background-color: #ff485e;
								}
								&:nth-child(4){
									background-color: #ff485e;
								}
							}
						}
						ol{
							z-index: 20;
							
							li:not(:last-child)::after{
								content: '';
								position: absolute;
								top: 0;
								right: -.06rem;
								width: 0.06rem;
								height: 100%;
								background-color: #fff;
								z-index: 20;
							}
						}
						.circle{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 0.06rem;
							transition: all .3s;
							
							&.step1::before{
								background-color: #338FFF;
								box-shadow:0 0 0.06rem 0 #338FFF;
							}
							&.step2::before{
								background-color: #2ee68d;
								box-shadow:0 0 0.06rem 0 #2ee68d;
							}
							&.step3::before{
								// background-color: #ffbb00;
								// box-shadow:0 0 0.06rem 0 #ffbb00;
								background-color: #ff485e;
								box-shadow:0 0 0.06rem 0 #ff485e;
							}
							&.step4::before{
								background-color: #ff485e;
								box-shadow:0 0 0.06rem 0 #ff485e;
							}
							
							&::before{
								content: '';
								position: absolute;
								top: -0.07rem;
								left: -0.1rem;
								width: 0.2rem;
								height: 0.2rem;
								border-radius: 50%;
								border: 0.02rem solid #fff;
								box-shadow:0 0 0.06rem 0 #E1E2E6;
								background-color: #E1E2E6;
								z-index: 100;
							}
							&::after{
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-color: #E1E2E6;
								z-index: 10;
							}
						}
					}
					.BMI .line{
						ul,ol{
							li{
								width: 2.06rem;
								&:nth-child(2){
									// width: 1.56rem;
								}
							}
						}
					}
					.WHR .line{
						ul,ol{
							li{
								
								width: 3.14rem;
								&:nth-child(2){
									width: 3.10rem;
								}
							}
						}
						ul{
							li{
								&:nth-child(1){
									background-color: #2ee68d;
								}
								&:nth-child(2){
									background-color: #ff485e;
								}
							}
						}
						.circle{
							&.step1::before{
								background-color: #2ee68d;
								box-shadow:0 0 0.06rem 0 #2ee68d;
							}
							&.step2::before{
								background-color: #ff485e;
								box-shadow:0 0 0.06rem 0 #ff485e;
							}
						}
					}
					.BFR .line{
						li{
							width: 2.06rem;
						}
					}
					// 不同阶段圆形图标颜色
					.BMI .you .step1 .circle::before,
					.BFR .group .step1 .circle::before{
						box-shadow:0 0 0.06rem 0 #338fff;
						background: #338fff;
					}
					.BMI .you .step2 .circle::before,
					.WHR .group .step1 .circle::before,
					.BFR .step2 .circle::before{
						box-shadow:0 0 0.06rem 0 #2ee68d;
						background: #2ee68d;
					}
					.BMI .you .step3 .circle::before,
					.BFR .group .step3 .circle::before{
						box-shadow:0 0 0.06rem 0 #ffbb00;
						background: #ffbb00;
					}
					.BMI .you .step4 .circle::before,
					.WHR .group .step2 .circle::before{
						box-shadow:0 0 0.06rem 0 #ff485e;
						background: #ff485e;
					}
				}
			}
			
			// 医疗费用预测
			.costs{
				padding: 0.48rem 0.24rem 0.8rem;
				
				li{
					padding: 0.35rem 0.48rem 0.4rem 0.35rem;
					background-color: #F5F7FA;
					border-radius: 0.08rem;
					margin-bottom: 0.24rem;
					
					.van-image{
						margin-top: 0.08rem;
					}
					&:first-child{
						dd{
							color: #F5495B;
						}
					}
					&:last-child{
						dd{
							color: #4575E5;
						}
					}
					b{
						font-size: 0.48rem;
					}
				}
				.costs-chart{
					height: 7.4rem;
				}
			}
		
			//未来疾病风险预测
			.illness{
				&>div:not(:last-child){
					border-bottom: 0.16rem solid #F0F2F5;
				}
				h3{
					font-size: 0.36rem;
					font-weight: 500;
					padding: 0.5rem 0.24rem 0;
				}
				li{
					padding: 0 0.24rem 0.5rem;
					
					&:not(:last-child){
						margin-bottom: 0.1rem;
						border-bottom: 0.01rem solid #F5F7FA;
					}
					&:first-child{
						.illness-t{
							padding-top: 0.4rem;
						}
					}
					.illness-t{
						line-height: 0.5rem;
						padding: 0.56rem 0 0.3rem;
						
						.name{
							font-size: 0.32rem;
						}
						.van-icon{
							vertical-align: middle;
						}
					}
					.chart{
						height: 5.35rem;
					}
					.prob{
						padding: 0.24rem 0;
						background-color: #F5F7FA;
						border-radius: 0.08rem;
						
						dl{
							line-height: 0.44rem;
							
							b{
								font-size: 0.34rem;
							}
							dt{
								font-weight: 500;
							}
						}
					}
					.symptom{
						color: #6A7180;
						line-height: 0.48rem;
						font-weight: 300;
						margin-top: 0.3rem;
						
						span{
							font-weight: 400;
						}
					}
					&>p.qjc-texta-l{
						font-weight: 300;
						line-height: 0.48rem;
						
						span{
							font-weight: 400;
						}
					}
				}
			}
		
			//不患病&增寿
			.noSick{
				background-color: #FFF;
				padding: 0 0.24rem;
				
				div{
					h3{
						padding: 0.4rem 0 0.28rem;
					}
					li{
						border-radius: 0.08rem;
						background-color: #F5F7FA;
						margin-bottom: 0.24rem;
						
						h4{
							padding: 0.3rem 0.27rem;
						}
						div{
							padding-bottom: 0.3rem;
							
							dl{
								line-height: 0.4rem;
							}
						}
						p{
							height: 0.9rem;
							line-height: 0.9rem;
							border-top: 0.04rem solid #fff;
						}
					}
				}
			}
		
			// 健康管理和改善方案
			.improve{
				padding: 0 0.24rem 2rem;
				
				.improve-body{
					.target{
						h3{
							padding: 0.5rem 0 0.3rem;
						}
						&>div{
							display: flex;
							justify-content: space-between;
							padding: 0.24rem 0.28rem;
							background-color: #F5F8FC;
							border-radius: 0.08rem;
							margin-bottom: 0.24rem;
							
							dt{
								margin-bottom: 0.36rem;
							}
							span{
								font-size: 0.48rem;
							}
							.van-image{
								margin-top: 0.98rem;
							}
						}
					}
					.program{
						h4{
							margin-top: 0.8rem;
						}
						.program-res{
							display: flex;
							justify-content: space-between;
							font-size: 0.2rem;
							margin: 0.4rem 0 0.6rem;
						}
						&>div>p{
							font-size: 0.2rem;
							color: #6A7180;
							line-height: 0.3rem;
							margin: 0.1rem 0;
						}
						.eat{
							li{
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding: 0 0.24rem;
								height: 1.02rem;
								background-color: #F5F8FC;
								border-radius: 0.08rem;
								margin-bottom: 0.12rem;
								
								.van-image{
									vertical-align: text-top;
								}
								b{
									font-size: 0.2rem;
									color: #6A7180;
									
									span{
										font-size: 0.28rem;
									}
								}
							}
						}
						.sport{
							table{
								$border: 0.02rem solid #F0F2F5;
								
								font-size: 0.2rem;
								color: #333;
								border-left: $border;
								border-top: $border;
								
								th{
									font-weight: normal;
									background-color: #EEF4FF;
									line-height: 0.58rem;
									padding: 0 0.12rem;
								}
								td{
									line-height: 0.94rem;
									padding: 0 0.12rem;
									border-bottom: $border;
									border-right: $border;
									
									span{
										font-weight: bold;
										font-size: 0.28rem;
									}
								}
							}
							.symptom{
								font-size: 0.2rem;
								line-height: 0.32rem;
								margin: 0.24rem 0;
							}
						}
					}
				}
				.improve-biom{
					margin-top: 0.6rem;
					
					.c-danger{
						color: #FF3131;
					}
					h3{
						margin-bottom: 0.06rem;
					}
					.improve-table{
						width: 100%;
						
						@media screen and (max-width: 374px){
							overflow-x: auto;
						}
					}
					table{
						$border2: 0.01rem solid #F5F5F5;
						font-size: 0.2rem;
						margin-top: 0.28rem;
						margin-bottom: 0.1rem;
						border: $border2;
						
						td{
							padding: 0.2rem 0.15rem;
						}
						li{
							padding: 0.2rem 0;
							border-bottom: $border2;
						}
						thead td{
							padding: 0.2rem 0.15rem;
							border-bottom: $border2;
						}
						tr:not(:last-child) td{
							border-bottom: $border2;
						}
						tr>td.left-side{
							width: 0.42rem;
							padding: 0.1rem;
							border-right: $border2;
						}
						.iconfont{
							margin-right: 0.04rem;
						}
					}
					.iconfont{
						font-size: 0.2rem;
					}
					.symptom{
						.iconfont{
							margin-right: 0.5rem;
						}
					}
				}
			}
		}
	}
</style>
